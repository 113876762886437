import React from 'react'

import { useLocation, Redirect } from '@reach/router'
import { App } from 'antd'

import { AuthConsumer } from '../providers/AuthProvider'

import Layout from '../components/Layout'
import PartnerScreen from '../components/PartnerScreen'
import SEO from '../components/SEO'

import { useFeatureFlags } from '../hooks/useFeatureFlags'

import { getSelectedId } from '../utils/helpers'

const PartnerPageContent = ({ authData }) => {
  const { features } = useFeatureFlags()
  const isPartnersPageEnabled = Boolean(features?.RELEASE_PARTNER_PROFILE)
  const location = useLocation()
  const partnerId = getSelectedId(location.search) || ''
  if (isPartnersPageEnabled) {
    return (<Redirect to={`/partners`} noThrow />)
  }

  return (
    <div className="content">
      {partnerId
        ? (
          <PartnerScreen authData={authData} partnerId={partnerId} />
        )
        : (
          <Redirect to="/partners" noThrow />
        )}
    </div>
  )
}

const PartnerPage = () => (
  <App>
    <Layout menuSelectedKeys={['roles']}>
      <SEO title="Partners" />

      <AuthConsumer>
        {({ isLoading, data }) => {
          if (isLoading) {
            return <div>Loading...</div>
          }

          if (!data) {
            return <div>Not logged in</div>
          }

          return <PartnerPageContent authData={data}/>
        }}
      </AuthConsumer>
    </Layout>
  </App>
)

export default PartnerPage
